/* eslint-disable global-require */
import slide86 from './slide-86.gif';
import slide87 from './slide-87.gif';
import slide88 from './slide-88.png';
import slide89 from './slide-89.gif';
import slide90 from './slide-90.png';
import slide91 from './slide-91.png';

export const carousel = {
    assets: {
        'slide-86': slide86,
        'slide-87': slide87,
        'slide-88': slide88,
        'slide-89': slide89,
        'slide-90': slide90,
        'slide-91': slide91,
    },
    autoPlaySpeed: 18000,
    customUrl: { 'slide-76': 'https://share.hsforms.com/1Q5nZGDbQSRqrD6qFwDRlnQ2irkd' },
    fallback: require('./carousel.en-GB.json'),
    languages: {
        'de-DE': require('./carousel.de-DE.json'),
        'es-ES': require('./carousel.es-ES.json'),
        'fr-FR': require('./carousel.fr-FR.json'),
        'it-IT': require('./carousel.it-IT.json'),
        'nl-NL': require('./carousel.nl-NL.json'),
        'pl-PL': require('./carousel.pl-PL.json'),
    },
    revision: 31,
};
