import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import type { TripReportSettings, TripReportSettingsResponse } from '~/data/tripReport';
import type { NumericDictionary } from '~/libs/utility';
import { keyBy } from '~/libs/utility';
import type {
    Classification,
    ClassificationResponse,
    CompanyCard,
    CompanyCardsResponse,
    CountriesResponse,
    Country,
    Culture,
    CulturesResponse,
    CurrenciesResponse,
    Currency,
    CustomerSecurables,
    CustomerSecurablesResponse,
    DeviceType,
    DeviceTypesResponse,
    DriverActivityType,
    DriverActivityTypesResponse,
    DriverEventType,
    DriverEventTypesResponse,
    DriverSubActivityType,
    DriverSubActivityTypesResponse,
    GetScoringProfilesRespone,
    GetTpiSettingsResponse,
    GetVehicleInhibitorCapabilitiesResponse,
    MessagingSettingsResponse,
    PositionGroupSettingsResponse,
    ScoringProfile,
    SecurablesResponse,
    TachoDataSettingsResponse,
    TachographDataBlock,
    TachographDataBlockResponse,
    VehicleCategoriesResponse,
    VehicleCategory,
    VehicleInhibitorCapabilities,
    VehicleMessagingCapabilities,
    VehicleMessagingCapabilitiesResponse,
    VehicleType,
    VehicleTypesResponse,
} from '~/services/ApiClient';
import {
    AlertManagerDefinitionSecurables,
    AlertSecurables,
    ApiClient,
    AssetAdministrationSecurables,
    AssetGroupAdministrationSecurables,
    AuthenticationApiClient,
    AutotranslateSecurables,
    ClassificationType,
    CompanyCardAdministrationSecurables,
    CustomerSettingsSecurables,
    DeviceAdministrationSecurables,
    DriverAdministrationSecurables,
    DriverCoachReportConfigurationSecurables,
    ExternalSystemSecurables,
    FormsAdministrationSecurables,
    GeozonesAdministrationSecurables,
    HistorySecurables,
    ImpersonationSecurables,
    LandmarksSecurables,
    MapLayersSecurables,
    MessagingSecurables,
    MessagingSettings,
    MonitoringSecurables,
    PlanningSecurables,
    PositionGroupSettings,
    RoutesSecurables,
    RoutingSecurables,
    RtdsSecurables,
    SaveMessagingSettingsRequest,
    SavePositionGroupSettingsRequest,
    SaveTpiSettingsRequest,
    Securables,
    SecurityAdministrationSecurables,
    ServicesSecurables,
    TachoDataSettings,
    TachoFileSecurables,
    TpiSettings,
    TrailerAdministrationSecurables,
    UserAdministrationSecurables,
    UserRolesAndPermissionsSecurables,
    VehicleAdministrationSecurables,
    VehicleTypesAdministrationSecurables,
    createApiModel,
} from '~/services/ApiClient';

export interface InjectedProps extends InjectedTranslationProps {}

export interface SettingDetails<K extends keyof SettingsDataType, P = unknown> {
    defaultState: SettingsDataType[K];
    processPayload: (payload: P) => SettingsDataType[K];
    retrieveDataFactory: (props: InjectedProps) => () => Promise<P>;
    updateData?: (item: SettingsDataType[K]) => Promise<void>;
}

export enum SettingsKey {
    ACTIVATION_STATUS = 'activationStatus',
    ATTACHMENT_TYPE = 'attachmentType',
    AXLES = 'axles',
    BILLING_GROUP = 'billingGroup',
    BILLING_STATUS = 'billingStatus',
    COMMUNICATION_SETTINGS = 'communicationSettings',
    COMPANY_CARDS = 'companyCards',
    COMPARTMENT_STATUS = 'compartmentStatus',
    CONTAINER_EVENT_TYPE = 'containerEventType',
    CONTAINER_TEMPERATURE_TYPE = 'containerTemperatureType',
    COUNTRIES = 'countries',
    CULTURES = 'cultures',
    CURRENCIES = 'currencies',
    CUSTOMER_SECURABLES = 'customerSecurables',
    DEVICETYPES = 'deviceTypes',
    DOOR_STATUS = 'doorStatus',
    DRIVERACTIVITYTYPES = 'driverActivityTypes',
    DRIVEREVENTTYPES = 'driverEventTypes',
    DRIVERSUBACTIVITYTYPES = 'driverSubActivityTypes',
    EBS_MANUFACTURER = 'ebsManufacturer',
    EMISSION_CLASS = 'emissionClass',
    FUEL_CONSUMPTION_TARGETS = 'fuel-consumption-targets',
    FUEL_TANK_TYPE = 'fuelTankType',
    GEOZONE_TYPE = 'geozoneType',
    HOOKED_STATUS = 'hookedStatus',
    IMAGE_CAPTURING_TYPE = 'imageCapturingType',
    INHIBITOR_SERVICE_TYPE = 'inhibitorServiceType',
    INHIBITOR_STATUS = 'inhibitorStatus',
    NAVIGATION_TYPE = 'navigationType',
    NETWORK = 'network',
    POSITION_GROUP_SETTINGS = 'positionGroupSettings',
    REEFER_ALARM = 'reeferAlarm',
    REEFER_BATTERY_TYPE = 'reeferBatteryType',
    REEFER_DATA_INTERFACE = 'reeferDataInterface',
    REEFER_MANUFACTURERS = 'reeferManufacturers',
    REEFER_OPERATION_MODE = 'reeferOperationMode',
    REEFER_POWER_MODE = 'reeferPowerMode',
    REEFER_SPEED = 'reeferSpeed',
    REEFER_STATUS = 'reeferStatus',
    ROUTESTATUSTYPES = 'routeStatusTypes',
    RTDS_ORDINAL_TYPE = 'rtdsOrdinalType',
    RTDS_PRIORITY = 'rtdsPriority',
    RTDS_SCHEDULE_FREQUENCY = 'rtdsScheduleFrequency',
    RTDS_SCHEDULE_TYPE = 'rtdsScheduleType',
    RTDS_SESSION_ERROR_TYPE = 'rtdsSessionErrorType',
    RTDS_WEEK_DAY_TYPE = 'rtdsWeekDayType',
    SCORING_DIMENSIONS = 'scoring-dimensions',
    SECURABLES = 'securables',
    TACHO_DATA = 'tachoData',
    TACHO_GENERATION = 'tachoGeneration',
    TACHO_PROTOCOL = 'tachoProtocol',
    TACHOGRAPH = 'tachograph',
    TACHOGRAPH_DATA_BLOCK = 'tachographDataBlock',
    TELL_TALE_SERVICE_TYPE = 'tellTaleServiceType',
    TPI_SETTING_SCORING_PROFILE_OPTIONS = 'tpiSettingScoringProfileOptions',
    TPI_SETTINGS = 'tpiSettings',
    TPMS_MANUFACTURER = 'tpmsManufacturer',
    TRAILER_BANDWIDTH_LINKED = 'trailerBandwidthLinked',
    TRAILER_BATTERY_STATUS = 'trailerBatteryStatus',
    TRAILER_BATTERY_TYPE = 'trailerBatteryType',
    TRAILER_EVENT_TYPE = 'trailerEventType',
    TRAILER_MANUFACTURERS = 'trailerManufacturers',
    TRAILER_MONITORING = 'trailerMonitoring',
    TRAILER_OFFLINE_ALARM = 'trailerOfflineAlarm',
    TRIP_REPORT = 'tripReport',
    VEHICLE_INHIBITOR_CAPABILITIES = 'vehicleInhibitorCapabilities',
    VEHICLE_MESSAGING_CAPABILITIES = 'vehicleMessagingCapabilities',
    VEHICLE_TYPE_CATEGORIES = 'vehicleTypeCategories',
    VEHICLECATEGORIES = 'vehicleCategories',
    VEHICLETYPES = 'vehicleTypes',
    WORKFLOW_TYPE = 'workflowType',
}

export interface SettingsDataType {
    [SettingsKey.ACTIVATION_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.ATTACHMENT_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.AXLES]: NumericDictionary<Classification>;
    [SettingsKey.BILLING_GROUP]: NumericDictionary<Classification>;
    [SettingsKey.BILLING_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.COMMUNICATION_SETTINGS]: MessagingSettings;
    [SettingsKey.COMPANY_CARDS]: NumericDictionary<CompanyCard>;
    [SettingsKey.COMPARTMENT_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.CONTAINER_EVENT_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.CONTAINER_TEMPERATURE_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.COUNTRIES]: NumericDictionary<Country>;
    [SettingsKey.CULTURES]: NumericDictionary<Culture>;
    [SettingsKey.CURRENCIES]: NumericDictionary<Currency>;
    [SettingsKey.CUSTOMER_SECURABLES]: NumericDictionary<CustomerSecurables>;
    [SettingsKey.DEVICETYPES]: NumericDictionary<DeviceType>;
    [SettingsKey.DOOR_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.DRIVERACTIVITYTYPES]: NumericDictionary<DriverActivityType>;
    [SettingsKey.DRIVEREVENTTYPES]: NumericDictionary<DriverEventType>;
    [SettingsKey.DRIVERSUBACTIVITYTYPES]: NumericDictionary<DriverSubActivityType>;
    [SettingsKey.EBS_MANUFACTURER]: NumericDictionary<Classification>;
    [SettingsKey.EMISSION_CLASS]: NumericDictionary<Classification>;
    [SettingsKey.FUEL_CONSUMPTION_TARGETS]: NumericDictionary<Classification>;
    [SettingsKey.FUEL_TANK_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.GEOZONE_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.HOOKED_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.IMAGE_CAPTURING_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.INHIBITOR_SERVICE_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.INHIBITOR_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.NAVIGATION_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.NETWORK]: NumericDictionary<Classification>;
    [SettingsKey.POSITION_GROUP_SETTINGS]: PositionGroupSettings;
    [SettingsKey.REEFER_ALARM]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_BATTERY_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_DATA_INTERFACE]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_MANUFACTURERS]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_OPERATION_MODE]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_POWER_MODE]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_SPEED]: NumericDictionary<Classification>;
    [SettingsKey.REEFER_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.ROUTESTATUSTYPES]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_ORDINAL_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_PRIORITY]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_SCHEDULE_FREQUENCY]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_SCHEDULE_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_SESSION_ERROR_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.RTDS_WEEK_DAY_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.SCORING_DIMENSIONS]: NumericDictionary<Classification>;
    [SettingsKey.SECURABLES]: Securables;
    [SettingsKey.TACHO_DATA]: TachoDataSettings;
    [SettingsKey.TACHO_GENERATION]: NumericDictionary<Classification>;
    [SettingsKey.TACHO_PROTOCOL]: NumericDictionary<Classification>;
    [SettingsKey.TACHOGRAPH_DATA_BLOCK]: NumericDictionary<TachographDataBlock>;
    [SettingsKey.TACHOGRAPH]: NumericDictionary<Classification>;
    [SettingsKey.TACHOGRAPH]: NumericDictionary<Classification>;
    [SettingsKey.TELL_TALE_SERVICE_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.TPI_SETTING_SCORING_PROFILE_OPTIONS]: ScoringProfile[];
    [SettingsKey.TPI_SETTINGS]: TpiSettings | undefined;
    [SettingsKey.TPMS_MANUFACTURER]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_BANDWIDTH_LINKED]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_BATTERY_STATUS]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_BATTERY_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_EVENT_TYPE]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_MANUFACTURERS]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_MONITORING]: NumericDictionary<Classification>;
    [SettingsKey.TRAILER_OFFLINE_ALARM]: NumericDictionary<Classification>;
    [SettingsKey.TRIP_REPORT]: TripReportSettings;
    [SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES]: NumericDictionary<VehicleInhibitorCapabilities>;
    [SettingsKey.VEHICLE_MESSAGING_CAPABILITIES]: NumericDictionary<VehicleMessagingCapabilities>;
    [SettingsKey.VEHICLE_TYPE_CATEGORIES]: NumericDictionary<Classification>;
    [SettingsKey.VEHICLECATEGORIES]: NumericDictionary<VehicleCategory>;
    [SettingsKey.VEHICLETYPES]: NumericDictionary<VehicleType>;
    [SettingsKey.WORKFLOW_TYPE]: NumericDictionary<Classification>;
}

export interface SettingsDetails {
    [SettingsKey.ACTIVATION_STATUS]: SettingDetails<SettingsKey.ACTIVATION_STATUS, ClassificationResponse>;
    [SettingsKey.ATTACHMENT_TYPE]: SettingDetails<SettingsKey.ATTACHMENT_TYPE, ClassificationResponse>;
    [SettingsKey.AXLES]: SettingDetails<SettingsKey.AXLES, ClassificationResponse>;
    [SettingsKey.BILLING_GROUP]: SettingDetails<SettingsKey.BILLING_GROUP, ClassificationResponse>;
    [SettingsKey.BILLING_STATUS]: SettingDetails<SettingsKey.BILLING_STATUS, ClassificationResponse>;
    [SettingsKey.COMMUNICATION_SETTINGS]: SettingDetails<SettingsKey.COMMUNICATION_SETTINGS, MessagingSettingsResponse>;
    [SettingsKey.COMPANY_CARDS]: SettingDetails<SettingsKey.COMPANY_CARDS, CompanyCardsResponse>;
    [SettingsKey.COMPARTMENT_STATUS]: SettingDetails<SettingsKey.COMPARTMENT_STATUS, ClassificationResponse>;
    [SettingsKey.CONTAINER_EVENT_TYPE]: SettingDetails<SettingsKey.CONTAINER_EVENT_TYPE, ClassificationResponse>;
    [SettingsKey.CONTAINER_TEMPERATURE_TYPE]: SettingDetails<
        SettingsKey.CONTAINER_TEMPERATURE_TYPE,
        ClassificationResponse
    >;
    [SettingsKey.COUNTRIES]: SettingDetails<SettingsKey.COUNTRIES, CountriesResponse>;
    [SettingsKey.CULTURES]: SettingDetails<SettingsKey.CULTURES, CulturesResponse>;
    [SettingsKey.CURRENCIES]: SettingDetails<SettingsKey.CURRENCIES, CurrenciesResponse>;
    [SettingsKey.CUSTOMER_SECURABLES]: SettingDetails<SettingsKey.CUSTOMER_SECURABLES, CustomerSecurablesResponse>;
    [SettingsKey.DEVICETYPES]: SettingDetails<SettingsKey.DEVICETYPES, DeviceTypesResponse>;
    [SettingsKey.DOOR_STATUS]: SettingDetails<SettingsKey.DOOR_STATUS, ClassificationResponse>;
    [SettingsKey.DRIVERACTIVITYTYPES]: SettingDetails<SettingsKey.DRIVERACTIVITYTYPES, DriverActivityTypesResponse>;
    [SettingsKey.DRIVEREVENTTYPES]: SettingDetails<SettingsKey.DRIVEREVENTTYPES, DriverEventTypesResponse>;
    [SettingsKey.DRIVERSUBACTIVITYTYPES]: SettingDetails<
        SettingsKey.DRIVERSUBACTIVITYTYPES,
        DriverSubActivityTypesResponse
    >;
    [SettingsKey.EBS_MANUFACTURER]: SettingDetails<SettingsKey.EBS_MANUFACTURER, ClassificationResponse>;
    [SettingsKey.EMISSION_CLASS]: SettingDetails<SettingsKey.EMISSION_CLASS, ClassificationResponse>;
    [SettingsKey.FUEL_CONSUMPTION_TARGETS]: SettingDetails<
        SettingsKey.FUEL_CONSUMPTION_TARGETS,
        ClassificationResponse
    >;
    [SettingsKey.FUEL_TANK_TYPE]: SettingDetails<SettingsKey.FUEL_TANK_TYPE, ClassificationResponse>;
    [SettingsKey.GEOZONE_TYPE]: SettingDetails<SettingsKey.GEOZONE_TYPE, ClassificationResponse>;
    [SettingsKey.HOOKED_STATUS]: SettingDetails<SettingsKey.HOOKED_STATUS, ClassificationResponse>;
    [SettingsKey.IMAGE_CAPTURING_TYPE]: SettingDetails<SettingsKey.IMAGE_CAPTURING_TYPE, ClassificationResponse>;
    [SettingsKey.INHIBITOR_SERVICE_TYPE]: SettingDetails<SettingsKey.INHIBITOR_SERVICE_TYPE, ClassificationResponse>;
    [SettingsKey.INHIBITOR_STATUS]: SettingDetails<SettingsKey.INHIBITOR_STATUS, ClassificationResponse>;
    [SettingsKey.NAVIGATION_TYPE]: SettingDetails<SettingsKey.NAVIGATION_TYPE, ClassificationResponse>;
    [SettingsKey.NETWORK]: SettingDetails<SettingsKey.NETWORK, ClassificationResponse>;
    [SettingsKey.POSITION_GROUP_SETTINGS]: SettingDetails<
        SettingsKey.POSITION_GROUP_SETTINGS,
        PositionGroupSettingsResponse
    >;
    [SettingsKey.REEFER_ALARM]: SettingDetails<SettingsKey.REEFER_ALARM, ClassificationResponse>;
    [SettingsKey.REEFER_BATTERY_TYPE]: SettingDetails<SettingsKey.REEFER_BATTERY_TYPE, ClassificationResponse>;
    [SettingsKey.REEFER_DATA_INTERFACE]: SettingDetails<SettingsKey.REEFER_DATA_INTERFACE, ClassificationResponse>;
    [SettingsKey.REEFER_MANUFACTURERS]: SettingDetails<SettingsKey.REEFER_MANUFACTURERS, ClassificationResponse>;
    [SettingsKey.REEFER_OPERATION_MODE]: SettingDetails<SettingsKey.REEFER_OPERATION_MODE, ClassificationResponse>;
    [SettingsKey.REEFER_POWER_MODE]: SettingDetails<SettingsKey.REEFER_POWER_MODE, ClassificationResponse>;
    [SettingsKey.REEFER_SPEED]: SettingDetails<SettingsKey.REEFER_SPEED, ClassificationResponse>;
    [SettingsKey.REEFER_STATUS]: SettingDetails<SettingsKey.REEFER_STATUS, ClassificationResponse>;
    [SettingsKey.ROUTESTATUSTYPES]: SettingDetails<SettingsKey.ROUTESTATUSTYPES, ClassificationResponse>;
    [SettingsKey.RTDS_ORDINAL_TYPE]: SettingDetails<SettingsKey.RTDS_ORDINAL_TYPE, ClassificationResponse>;
    [SettingsKey.RTDS_PRIORITY]: SettingDetails<SettingsKey.RTDS_PRIORITY, ClassificationResponse>;
    [SettingsKey.RTDS_SCHEDULE_FREQUENCY]: SettingDetails<SettingsKey.RTDS_SCHEDULE_FREQUENCY, ClassificationResponse>;
    [SettingsKey.RTDS_SCHEDULE_TYPE]: SettingDetails<SettingsKey.RTDS_SCHEDULE_TYPE, ClassificationResponse>;
    [SettingsKey.RTDS_SESSION_ERROR_TYPE]: SettingDetails<SettingsKey.RTDS_SESSION_ERROR_TYPE, ClassificationResponse>;
    [SettingsKey.RTDS_WEEK_DAY_TYPE]: SettingDetails<SettingsKey.RTDS_WEEK_DAY_TYPE, ClassificationResponse>;
    [SettingsKey.SCORING_DIMENSIONS]: SettingDetails<SettingsKey.SCORING_DIMENSIONS, ClassificationResponse>;
    [SettingsKey.SECURABLES]: SettingDetails<SettingsKey.SECURABLES, SecurablesResponse>;
    [SettingsKey.TACHO_DATA]: SettingDetails<SettingsKey.TACHO_DATA, TachoDataSettingsResponse>;
    [SettingsKey.TACHO_GENERATION]: SettingDetails<SettingsKey.TACHO_GENERATION, ClassificationResponse>;
    [SettingsKey.TACHO_PROTOCOL]: SettingDetails<SettingsKey.TACHO_PROTOCOL, ClassificationResponse>;
    [SettingsKey.TACHOGRAPH_DATA_BLOCK]: SettingDetails<SettingsKey.TACHOGRAPH_DATA_BLOCK, TachographDataBlockResponse>;
    [SettingsKey.TACHOGRAPH]: SettingDetails<SettingsKey.TACHOGRAPH, ClassificationResponse>;
    [SettingsKey.TELL_TALE_SERVICE_TYPE]: SettingDetails<SettingsKey.TELL_TALE_SERVICE_TYPE, ClassificationResponse>;
    [SettingsKey.TPI_SETTING_SCORING_PROFILE_OPTIONS]: SettingDetails<
        SettingsKey.TPI_SETTING_SCORING_PROFILE_OPTIONS,
        GetScoringProfilesRespone
    >;
    [SettingsKey.TPI_SETTINGS]: SettingDetails<SettingsKey.TPI_SETTINGS, GetTpiSettingsResponse>;
    [SettingsKey.TPMS_MANUFACTURER]: SettingDetails<SettingsKey.TPMS_MANUFACTURER, ClassificationResponse>;
    [SettingsKey.TRAILER_BANDWIDTH_LINKED]: SettingDetails<
        SettingsKey.TRAILER_BANDWIDTH_LINKED,
        ClassificationResponse
    >;
    [SettingsKey.TRAILER_BATTERY_STATUS]: SettingDetails<SettingsKey.TRAILER_BATTERY_STATUS, ClassificationResponse>;
    [SettingsKey.TRAILER_BATTERY_TYPE]: SettingDetails<SettingsKey.TRAILER_BATTERY_TYPE, ClassificationResponse>;
    [SettingsKey.TRAILER_EVENT_TYPE]: SettingDetails<SettingsKey.TRAILER_EVENT_TYPE, ClassificationResponse>;
    [SettingsKey.TRAILER_MANUFACTURERS]: SettingDetails<SettingsKey.TRAILER_MANUFACTURERS, ClassificationResponse>;
    [SettingsKey.TRAILER_MONITORING]: SettingDetails<SettingsKey.TRAILER_MONITORING, ClassificationResponse>;
    [SettingsKey.TRAILER_OFFLINE_ALARM]: SettingDetails<SettingsKey.TRAILER_OFFLINE_ALARM, ClassificationResponse>;
    [SettingsKey.TRIP_REPORT]: SettingDetails<SettingsKey.TRIP_REPORT, TripReportSettingsResponse>;
    [SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES]: SettingDetails<
        SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES,
        GetVehicleInhibitorCapabilitiesResponse
    >;
    [SettingsKey.VEHICLE_MESSAGING_CAPABILITIES]: SettingDetails<
        SettingsKey.VEHICLE_MESSAGING_CAPABILITIES,
        VehicleMessagingCapabilitiesResponse
    >;
    [SettingsKey.VEHICLE_TYPE_CATEGORIES]: SettingDetails<SettingsKey.VEHICLE_TYPE_CATEGORIES, ClassificationResponse>;
    [SettingsKey.VEHICLECATEGORIES]: SettingDetails<SettingsKey.VEHICLECATEGORIES, VehicleCategoriesResponse>;
    [SettingsKey.VEHICLETYPES]: SettingDetails<SettingsKey.VEHICLETYPES, VehicleTypesResponse>;
    [SettingsKey.WORKFLOW_TYPE]: SettingDetails<SettingsKey.WORKFLOW_TYPE, ClassificationResponse>;
}

export const settingsDetails: SettingsDetails = {
    [SettingsKey.ACTIVATION_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ActivationStatus),
    },
    [SettingsKey.ATTACHMENT_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.AttachmentType),
    },
    [SettingsKey.AXLES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.Axles),
    },
    [SettingsKey.BILLING_GROUP]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.BillingGroup),
    },
    [SettingsKey.BILLING_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.BillingStatus),
    },
    [SettingsKey.COMMUNICATION_SETTINGS]: {
        defaultState: createApiModel(MessagingSettings, { maxQueryPeriod: 'P2D', messageRetentionPeriod: 'PT14D' }),
        processPayload: ({ item }: Readonly<MessagingSettingsResponse>): MessagingSettings => item,
        retrieveDataFactory: () => () => ApiClient.getMessagingSettings(),
        updateData: (item: MessagingSettings): Promise<void> =>
            ApiClient.saveMessagingSettings(createApiModel(SaveMessagingSettingsRequest, { item })),
    },
    [SettingsKey.COMPANY_CARDS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<CompanyCardsResponse>): NumericDictionary<CompanyCard> =>
            keyBy(items, 'serialNumber'),
        retrieveDataFactory: () => () => ApiClient.getCompanyCards(),
    },
    [SettingsKey.COMPARTMENT_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.CompartmentStatus),
    },
    [SettingsKey.CONTAINER_EVENT_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ContainerEventType),
    },
    [SettingsKey.CONTAINER_TEMPERATURE_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ContainerTemperatureType),
    },
    [SettingsKey.COUNTRIES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<CountriesResponse>): NumericDictionary<Country> => keyBy(items, 'code'),
        retrieveDataFactory: () => () => ApiClient.getCountries(),
    },
    [SettingsKey.CULTURES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<CulturesResponse>): NumericDictionary<Culture> => keyBy(items, 'code'),
        retrieveDataFactory: () => () => ApiClient.getCultures(),
    },
    [SettingsKey.CURRENCIES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<CurrenciesResponse>): NumericDictionary<Currency> => keyBy(items, 'code'),
        retrieveDataFactory: () => () => ApiClient.getCurrencies(),
    },
    [SettingsKey.CUSTOMER_SECURABLES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<CustomerSecurablesResponse>): NumericDictionary<CustomerSecurables> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => AuthenticationApiClient.getCustomerSecurables(),
    },
    [SettingsKey.DEVICETYPES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<DeviceTypesResponse>): NumericDictionary<DeviceType> => {
            // FVNXT-6899 0 - MCT / 5 - MCP200
            const filtered = items.filter((item) => item.id !== 0 && item.id !== 5);
            return keyBy(filtered, 'id');
        },
        retrieveDataFactory: () => () => ApiClient.getDeviceTypes(),
    },
    [SettingsKey.DOOR_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.DoorStatus),
    },
    [SettingsKey.DRIVERACTIVITYTYPES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<DriverActivityTypesResponse>): NumericDictionary<DriverActivityType> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getDriverActivityTypes(),
    },
    [SettingsKey.DRIVEREVENTTYPES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<DriverSubActivityTypesResponse>): NumericDictionary<DriverEventType> =>
            keyBy(items, 'id'),
        retrieveDataFactory: (props: InjectedProps) => () => ApiClient.getDriverEventTypes(props.i18n.language),
    },
    [SettingsKey.DRIVERSUBACTIVITYTYPES]: {
        defaultState: {},
        processPayload: ({
            items,
        }: Readonly<DriverSubActivityTypesResponse>): NumericDictionary<DriverSubActivityType> => keyBy(items, 'id'),
        retrieveDataFactory: (props: InjectedProps) => () => ApiClient.getDriverSubActivityTypes(props.i18n.language),
    },
    [SettingsKey.EBS_MANUFACTURER]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.EbsManufacturer),
    },
    [SettingsKey.EMISSION_CLASS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.EmissionClass),
    },
    [SettingsKey.FUEL_CONSUMPTION_TARGETS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.FuelConsumptionTarget),
    },
    [SettingsKey.FUEL_TANK_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.FuelTankType),
    },
    [SettingsKey.GEOZONE_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.GeozoneType),
    },
    [SettingsKey.HOOKED_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.HookedStatus),
    },
    [SettingsKey.IMAGE_CAPTURING_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ImageCapturingType),
    },
    [SettingsKey.INHIBITOR_SERVICE_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.InhibitorServiceType),
    },
    [SettingsKey.INHIBITOR_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.InhibitorStatus),
    },
    [SettingsKey.NAVIGATION_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.NavigationType),
    },
    [SettingsKey.NETWORK]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.Network),
    },
    [SettingsKey.POSITION_GROUP_SETTINGS]: {
        defaultState: createApiModel(PositionGroupSettings, { minStopDuration: 'PT0M' }),
        processPayload: ({ item }: Readonly<PositionGroupSettingsResponse>): PositionGroupSettings => item,
        retrieveDataFactory: () => () => ApiClient.getPositionGroupSettings(),
        updateData: (item: PositionGroupSettings): Promise<void> =>
            ApiClient.savePositionGroupSettings(createApiModel(SavePositionGroupSettingsRequest, { item })),
    },
    [SettingsKey.REEFER_ALARM]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferAlarm),
    },
    [SettingsKey.REEFER_BATTERY_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferBatteryType),
    },
    [SettingsKey.REEFER_DATA_INTERFACE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferDataInterface),
    },
    [SettingsKey.REEFER_MANUFACTURERS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferManufacturer),
    },
    [SettingsKey.REEFER_OPERATION_MODE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferOperationMode),
    },
    [SettingsKey.REEFER_POWER_MODE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferPowerMode),
    },
    [SettingsKey.REEFER_SPEED]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferSpeed),
    },
    [SettingsKey.REEFER_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ReeferStatus),
    },
    [SettingsKey.ROUTESTATUSTYPES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RouteStatus),
    },
    [SettingsKey.RTDS_ORDINAL_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsOrdinalType),
    },
    [SettingsKey.RTDS_PRIORITY]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsPriority),
    },
    [SettingsKey.RTDS_SCHEDULE_FREQUENCY]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsScheduleFrequency),
    },
    [SettingsKey.RTDS_SCHEDULE_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsScheduleType),
    },
    [SettingsKey.RTDS_SESSION_ERROR_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsSessionErrorType),
    },
    [SettingsKey.RTDS_WEEK_DAY_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.RtdsWeekDayType),
    },
    [SettingsKey.SCORING_DIMENSIONS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.ScoringDimensions),
    },
    [SettingsKey.SECURABLES]: {
        defaultState: createApiModel(Securables, {
            alertManager: createApiModel(AlertSecurables, { isAllowed: true }),
            assetAdministration: createApiModel(AssetAdministrationSecurables, {
                assetGroups: createApiModel(AssetGroupAdministrationSecurables, { isAllowed: false }),
                companyCards: createApiModel(CompanyCardAdministrationSecurables, { isAllowed: false }),
                devices: createApiModel(DeviceAdministrationSecurables, {
                    canUnassignAsset: false,
                    isAllowed: false,
                }),
                drivers: createApiModel(DriverAdministrationSecurables, {
                    driverCreation: false,
                    driverDeletion: false,
                    driverModification: false,
                    isAllowed: false,
                }),
                trailers: createApiModel(TrailerAdministrationSecurables, {
                    isAllowed: false,
                    serviceActivation: false,
                    trailerCreation: false,
                    trailerDeletion: false,
                    trailerModification: false,
                }),
                vehicles: createApiModel(VehicleAdministrationSecurables, {
                    canUnassignDevice: false,
                    isAllowed: false,
                    serviceActivation: false,
                    vehicleCreation: false,
                    vehicleDeletion: false,
                    vehicleModification: false,
                }),
                vehicleTypes: createApiModel(VehicleTypesAdministrationSecurables, { isAllowed: false }),
            }),
            autotranslate: createApiModel(AutotranslateSecurables, {
                isAllowed: false,
            }),
            customerSettings: createApiModel(CustomerSettingsSecurables, {
                communicationSettings: false,
                manageSettings: false,
                positionGroups: false,
            }),
            driverCoachReportConfiguration: createApiModel(DriverCoachReportConfigurationSecurables, {
                isAllowed: false,
            }),
            externalSystem: createApiModel(ExternalSystemSecurables, {
                dashboard: false,
                tachOnline: false,
                timediscWeb: false,
                videoLinc: false,
            }),
            formsAdministration: createApiModel(FormsAdministrationSecurables, {
                alertManagerDefinitions: createApiModel(AlertManagerDefinitionSecurables, {
                    isAllowed: false,
                }),
            }),
            geozonesAdministration: createApiModel(GeozonesAdministrationSecurables, { isAllowed: false }),
            history: createApiModel(HistorySecurables, {
                drivers: false,
                trailers: false,
                vehicles: false,
            }),
            impersonation: createApiModel(ImpersonationSecurables, { impersonate: false }),
            mapLayers: createApiModel(MapLayersSecurables, {
                satelliteAllowed: false,
                trafficIncidentsAllowed: false,
                truckAttributesAllowed: false,
            }),
            messaging: createApiModel(MessagingSecurables, {
                deleteMessages: false,
                markMessagesAsRead: false,
                sendTextMessage: false,
                sendWorkflowMessage: false,
                startInhibitor: false,
                view: false,
                viewDeletedMessages: false,
            }),
            monitoring: createApiModel(MonitoringSecurables, {
                containers: false,
                driverStatus: false,
                historicPosition: false,
                map: false,
                trailers: false,
                vehicles: false,
            }),
            planning: createApiModel(PlanningSecurables, {
                landmarks: createApiModel(LandmarksSecurables, {
                    assignLandmarks: false,
                    defineLandmarks: false,
                }),
            }),
            routing: createApiModel(RoutingSecurables, {
                navigation: false,
                routes: createApiModel(RoutesSecurables, {
                    isAllowed: false,
                }),
            }),
            rtds: createApiModel(RtdsSecurables, {
                schedules: false,
                sessions: false,
                tachoLinc: false,
            }),
            securityAdministration: createApiModel(SecurityAdministrationSecurables, {
                userRolesAndPermissions: createApiModel(UserRolesAndPermissionsSecurables, {
                    isAllowed: false,
                }),
                users: createApiModel(UserAdministrationSecurables, {
                    isAllowed: false,
                }),
            }),
            services: createApiModel(ServicesSecurables, {
                alertsAndPanic: false,
                appStore: false,
                assetsNearby: false,
                autotranslate: false,
                driverCards: false,
                driverCoach: false,
                driverHours: false,
                drivingStyleEvaluation: false,
                frequentPositioning: false,
                fuelSiteAdvice: false,
                geozones: false,
                guidedNavigation: false,
                imageCapturing: false,
                inhibitorService: false,
                j1939DataService: false,
                kpi2: false,
                landmarks: false,
                navigation: false,
                navigationEta: false,
                rtds: false,
                tellTaleService: false,
                textMessaging: false,
                tireControl: false,
                trailerControlService: false,
                trailers: false,
                truckIdentification: false,
                workflow: false,
            }),
            tachoFile: createApiModel(TachoFileSecurables, {
                driverCardFiles: false,
                rms: false,
                tachographFiles: false,
            }),
        }),
        processPayload: ({ item }: Readonly<SecurablesResponse>): Securables => item,
        retrieveDataFactory: () => () => ApiClient.getSecurables(),
    },
    [SettingsKey.TACHO_DATA]: {
        defaultState: createApiModel(TachoDataSettings, { rmsEnabled: true }),
        processPayload: ({ item }: Readonly<TachoDataSettingsResponse>): TachoDataSettings => item,
        retrieveDataFactory: () => () => ApiClient.getTachoDataSettings(),
    },
    [SettingsKey.TACHO_GENERATION]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TachoGeneration),
    },
    [SettingsKey.TACHO_PROTOCOL]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TachoProtocol),
    },
    [SettingsKey.TACHOGRAPH]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.Tachograph),
    },
    [SettingsKey.TACHOGRAPH_DATA_BLOCK]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<TachographDataBlockResponse>): NumericDictionary<TachographDataBlock> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getTachoDataBlocks(),
    },
    [SettingsKey.TELL_TALE_SERVICE_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TellTaleServiceType),
    },
    [SettingsKey.TPI_SETTING_SCORING_PROFILE_OPTIONS]: {
        defaultState: [],
        processPayload: ({ items }) => items,
        retrieveDataFactory: () => () => ApiClient.getScoringProfiles(),
    },
    [SettingsKey.TPI_SETTINGS]: {
        defaultState: createApiModel(TpiSettings, { scoringProfileName: '' }),
        processPayload: (response) => response?.item,
        retrieveDataFactory: () => () => ApiClient.getTpiSettings(),
        updateData: (item: TpiSettings): Promise<void> =>
            ApiClient.saveTpiSettings(createApiModel(SaveTpiSettingsRequest, { item })),
    },
    [SettingsKey.TPMS_MANUFACTURER]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TpmsManufacturer),
    },
    [SettingsKey.TRAILER_BANDWIDTH_LINKED]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerBandwidthLinked),
    },
    [SettingsKey.TRAILER_BATTERY_STATUS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerBatteryStatus),
    },
    [SettingsKey.TRAILER_BATTERY_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerBatteryType),
    },
    [SettingsKey.TRAILER_EVENT_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerEventType),
    },
    [SettingsKey.TRAILER_MANUFACTURERS]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerManufacturer),
    },
    [SettingsKey.TRAILER_MONITORING]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerMonitoring),
    },
    [SettingsKey.TRAILER_OFFLINE_ALARM]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.TrailerOfflineAlarm),
    },
    [SettingsKey.TRIP_REPORT]: {
        defaultState: { maxDays: 'P90DT', maxQueryPeriod: 'P2Y' },
        processPayload: ({ item }) => item,
        retrieveDataFactory: () => () => ApiClient.getTripReportSettings(),
    },
    [SettingsKey.VEHICLE_INHIBITOR_CAPABILITIES]: {
        defaultState: {},
        processPayload: ({
            items,
        }: Readonly<GetVehicleInhibitorCapabilitiesResponse>): NumericDictionary<VehicleInhibitorCapabilities> =>
            keyBy(items, 'vehicleId'),
        retrieveDataFactory: () => () => ApiClient.getVehicleInhibitorCapabilities(),
    },
    [SettingsKey.VEHICLE_MESSAGING_CAPABILITIES]: {
        defaultState: {},
        processPayload: ({
            items,
        }: Readonly<VehicleMessagingCapabilitiesResponse>): NumericDictionary<VehicleMessagingCapabilities> =>
            keyBy(items, 'vehicleId'),
        retrieveDataFactory: () => () => ApiClient.getMessagingVehicleCapabilities(),
    },
    [SettingsKey.VEHICLE_TYPE_CATEGORIES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.VehicleTypeCategory),
    },
    [SettingsKey.VEHICLECATEGORIES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<VehicleCategoriesResponse>): NumericDictionary<VehicleCategory> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getVehicleCategories(),
    },
    [SettingsKey.VEHICLETYPES]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<VehicleTypesResponse>): NumericDictionary<VehicleType> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getVehicleTypes(),
    },
    [SettingsKey.WORKFLOW_TYPE]: {
        defaultState: {},
        processPayload: ({ items }: Readonly<ClassificationResponse>): NumericDictionary<Classification> =>
            keyBy(items, 'id'),
        retrieveDataFactory: () => () => ApiClient.getClassificationByType(ClassificationType.WorkflowType),
    },
};
