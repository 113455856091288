import { Backdrop, Box, CircularProgress } from '@mui/material';
import type { WithStyles } from '@mui/styles';
import * as React from 'react';
import type { RouteComponentProps } from 'react-router';
import { Redirect, useHistory } from 'react-router';

import { AssetSelector, StaticDataType } from '~/common';
import { useContextMenu, useEnsureData } from '~/common/hooks';
import { AskForPageReload } from '~/components/AskForPageReload';
import type { ContextMenuPosition } from '~/components/ContextMenu';
import type { DateTimeRange } from '~/components/DateTimeRangePicker';
import { DateTimeRangePicker } from '~/components/DateTimeRangePicker';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import { useEtherealState } from '~/components/EtherealState';
import type { GridVirtualTableElement } from '~/components/Grid/models';
import { DriverActivitiesGraphIcon } from '~/components/Icons';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { PageTemplate } from '~/components/PageTemplate';
import { SceneLoader } from '~/components/SceneLoader';
import { SplitView } from '~/components/SplitView';
import { TitledIconButton } from '~/components/TitledIconButton';
import { useUserPreferences } from '~/components/UserPreferences';
import { isUndefined } from '~/libs/utility';
import { SceneRoutes } from '~/routes';
import type { ResolvedAsset } from '~/services/ApiClient';
import { AssetReference, AssetType, createApiModel } from '~/services/ApiClient';
import { formatDriverName, formatTrailerName, formatVehicleName } from '~/services/Formatters';

import { DriverDetailsPane, TrailerDetailsPane, VehicleDetailsPane } from './components/DetailsPane';
import { DriverEventsGraphPanel } from './components/DriverEventsGraphPanel';
import { HistoryEventContextMenu } from './components/HistoryEventContextMenu';
import { DriverListViewHeader, HistoryList, TrailerListViewHeader, VehicleListViewHeader } from './components/List';
import { constructHistoryUrl } from './constructHistoryUrl';
import type { HistoryAssetEntry, HistoryDriverEntry, HistoryTrailerEntry, HistoryVehicleEntry } from './models';
import { HistoryEventType } from './models';
import type { HistoryUserPreferences } from './preferences';
import {
    HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY,
    HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY,
    HISTORY_USERPREFERENCES_KEY,
    HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY,
} from './preferences';
import type { HistoryClassKeys } from './styles';

export interface HistoryUrlParams {
    eventType?: HistoryEventType;
    selectedAssetId?: string;
    selectedAssetType?: AssetType;
}

export interface EtherealKeyState {
    selectedAssetId: number;
    selectedAssetType: AssetType;
}

export interface EtherealState {
    [k: string]: EtherealKeyState | undefined;
}

export interface HistoryProps extends RouteComponentProps<HistoryUrlParams> {}

export interface HistoryReduxProps {
    changeDateTimeRange: (dateTimeRange: DateTimeRange) => void;
    clearData: () => void;
    contentLoading: boolean;
    contentRejected: boolean;
    dataSource: HistoryAssetEntry[];
    dateTimeRange: DateTimeRange;
    eventTypeFromUrl: HistoryEventType;
    getEvents: (
        eventType: HistoryEventType,
        selectedAssetType: AssetType,
        selectedAssetId: number,
        startDate: Date,
        endDate: Date
    ) => void;
    getSelectedHistoryEntry: (selectedHistoryEntryId: number) => HistoryAssetEntry | undefined;
    leftPaneIsOpen: boolean;
    permittedEventTypes: HistoryEventType[];
    selectedAsset?: ResolvedAsset;
    selectedAssetIdFromUrl?: number;
    selectedAssetTypeFromUrl?: AssetType;
    toggleLeftPaneVisibility: () => void;
}

export interface HistoryEventContextMenuStateProps {
    eventId?: number;
    eventType?: HistoryEventType;
    position?: ContextMenuPosition;
}

export interface HistoryInnerProps
    extends HistoryProps,
        HistoryReduxProps,
        InjectedTranslationProps,
        InjectedDisplayPreferencesProps,
        WithStyles<HistoryClassKeys> {}

export const reloadPage = (): void => window.location.reload();

const defaultEtherealState: EtherealState = {};
export const HistoryComponent: React.FC<HistoryInnerProps> = ({
    changeDateTimeRange,
    classes,
    clearData,
    contentLoading,
    contentRejected,
    dataSource,
    dateTimeRange,
    displayPreferences,
    eventTypeFromUrl,
    getEvents,
    getSelectedHistoryEntry,
    leftPaneIsOpen,
    permittedEventTypes,
    selectedAsset,
    selectedAssetIdFromUrl,
    selectedAssetTypeFromUrl,
    t,
    toggleLeftPaneVisibility,
}) => {
    const history = useHistory();

    const { currentClicked, handleClose, handleRowClick, isOpen, position } = useContextMenu<number>();
    const [selectedEntryId, setSelectedEntryId] = React.useState<number | undefined>(undefined);
    const [hoveredEntryId, setHoveredEntryId] = React.useState<number | undefined>();

    const vtRef = React.useRef<GridVirtualTableElement>(null);

    const [etherealState, setEtherealState] = useEtherealState(defaultEtherealState, 'asset-history-route-parameters');

    const historyRouteState = etherealState[eventTypeFromUrl] ?? {
        selectedAssetId: undefined,
        selectedAssetType: undefined,
    };

    const selectedAssetId = selectedAssetIdFromUrl ?? historyRouteState.selectedAssetId;
    const selectedAssetType = selectedAssetTypeFromUrl ?? historyRouteState.selectedAssetType;
    const selectedAssetIds = React.useMemo<AssetReference[]>(() => {
        if (isUndefined(selectedAssetId) || isUndefined(selectedAssetType)) {
            return [];
        }

        return [createApiModel(AssetReference, { id: selectedAssetId, type: selectedAssetType })];
    }, [selectedAssetId, selectedAssetType]);

    const { error, isFetching, isInitialLoad } = useEnsureData({
        staticData: [
            StaticDataType.ASSETGROUPS,
            StaticDataType.DEPOTS,
            StaticDataType.VEHICLES,
            StaticDataType.TRAILERS,
            StaticDataType.DRIVERS,
        ],
    });

    React.useEffect(() => {
        return () => {
            clearData();
        };
    }, [clearData]);

    React.useEffect(() => {
        setSelectedEntryId(undefined);

        if (!isUndefined(selectedAssetIdFromUrl) && !isUndefined(selectedAssetTypeFromUrl)) {
            setEtherealState({
                [eventTypeFromUrl]: {
                    selectedAssetId: selectedAssetIdFromUrl,
                    selectedAssetType: selectedAssetTypeFromUrl,
                },
            });

            getEvents(
                eventTypeFromUrl,
                selectedAssetTypeFromUrl,
                selectedAssetIdFromUrl,
                dateTimeRange.startDate,
                dateTimeRange.endDate
            );
        }
    }, [
        eventTypeFromUrl,
        dateTimeRange,
        selectedAssetIdFromUrl,
        selectedAssetTypeFromUrl,
        setEtherealState,
        getEvents,
    ]);

    const getSelectAssetText = () => {
        switch (eventTypeFromUrl) {
            case HistoryEventType.DRIVER:
                return t('no-asset-selected-left-driver');
            case HistoryEventType.TRAILER:
                return t('no-asset-selected-left-trailer');
            case HistoryEventType.VEHICLE:
                return t('no-asset-selected-left-vehicle');
            default:
                throw Error(`Unknown history event type: ${eventTypeFromUrl}`);
        }
    };

    const getTitle = (historyEventType: HistoryEventType) => {
        if (selectedAsset) {
            switch (historyEventType) {
                case HistoryEventType.VEHICLE: {
                    if (selectedAssetType === AssetType.Vehicle) {
                        return t('history-of-asset', {
                            assetName: formatVehicleName(selectedAsset, displayPreferences.vehicleDisplayFormat),
                        });
                    } else {
                        throw Error(
                            `Selected AssetType ${selectedAssetType} is not supported for history event type: ${historyEventType}`
                        );
                    }
                }
                case HistoryEventType.TRAILER: {
                    if (selectedAssetType === AssetType.Trailer) {
                        return t('history-of-asset', {
                            assetName: formatTrailerName(selectedAsset, displayPreferences.trailerDisplayFormat),
                        });
                    } else if (selectedAssetType === AssetType.Vehicle) {
                        return t('trailer-history-by-vehicle', {
                            vehicleName: formatVehicleName(selectedAsset, displayPreferences.vehicleDisplayFormat),
                        });
                    } else {
                        throw Error(
                            `Selected AssetType ${selectedAssetType} is not supported for history event type: ${historyEventType}`
                        );
                    }
                }
                case HistoryEventType.DRIVER: {
                    if (selectedAssetType === AssetType.Driver) {
                        return t('history-of-asset', {
                            assetName: formatDriverName(selectedAsset, displayPreferences.driverDisplayFormat),
                        });
                    } else if (selectedAssetType === AssetType.Vehicle) {
                        return t('driver-history-by-vehicle', {
                            vehicleName: formatVehicleName(selectedAsset, displayPreferences.vehicleDisplayFormat),
                        });
                    } else {
                        throw Error(
                            `Selected AssetType ${selectedAssetType} is not supported for history event type: ${historyEventType}`
                        );
                    }
                }
                default:
                    throw Error(`Unknown history event type: ${historyEventType}`);
            }
        } else {
            switch (historyEventType) {
                case HistoryEventType.DRIVER:
                    return t('driver-activities');
                case HistoryEventType.TRAILER:
                    return t('trailer-events');
                case HistoryEventType.VEHICLE:
                    return t('historic-positions');
                default:
                    return t('history');
            }
        }
    };

    const [historyUserPreferences, setHistoryUserPreferences] =
        useUserPreferences<HistoryUserPreferences>(HISTORY_USERPREFERENCES_KEY);

    const toggleDriverActivitiesGraph = React.useCallback(() => {
        setHistoryUserPreferences({
            ...historyUserPreferences,
            driverActivityGraphToggleState: !historyUserPreferences.driverActivityGraphToggleState,
        });
    }, [historyUserPreferences, setHistoryUserPreferences]);

    const getHeaderBar = () => {
        const filter = <DateTimeRangePicker onChange={changeDateTimeRange} value={dateTimeRange} />;

        switch (eventTypeFromUrl) {
            case HistoryEventType.DRIVER:
                return (
                    <DriverListViewHeader
                        dataSource={dataSource as HistoryDriverEntry[]}
                        filters={filter}
                        gridActionsDisabled={isUndefined(selectedAssetId)}
                        moduleBarActions={
                            <TitledIconButton
                                color="inherit"
                                data-id="toggle-activities-graph"
                                onClick={toggleDriverActivitiesGraph}
                                title={
                                    historyUserPreferences.driverActivityGraphToggleState
                                        ? t('driver-activities-hide-graph')
                                        : t('driver-activities-show-graph')
                                }
                            >
                                <DriverActivitiesGraphIcon />
                            </TitledIconButton>
                        }
                        title={getTitle(HistoryEventType.DRIVER)}
                    />
                );
            case HistoryEventType.TRAILER:
                return (
                    <TrailerListViewHeader
                        dataSource={dataSource as HistoryTrailerEntry[]}
                        filters={filter}
                        gridActionsDisabled={isUndefined(selectedAssetId)}
                        title={getTitle(HistoryEventType.TRAILER)}
                    />
                );
            case HistoryEventType.VEHICLE:
                return (
                    <VehicleListViewHeader
                        dataSource={dataSource as HistoryVehicleEntry[]}
                        filters={filter}
                        gridActionsDisabled={isUndefined(selectedAssetId)}
                        title={getTitle(HistoryEventType.VEHICLE)}
                    />
                );
            default:
                throw Error(`Unknown history event type: ${eventTypeFromUrl}`);
        }
    };

    const selectRowFromGraph = React.useCallback((selectedHistoryEntryId: number) => {
        if (vtRef.current?.scrollIntoView) {
            vtRef.current?.scrollIntoView(selectedHistoryEntryId);
        }
        setSelectedEntryId(selectedHistoryEntryId);
    }, []);

    const closeRightPane = React.useCallback(() => setSelectedEntryId(undefined), []);
    const rightPane = (() => {
        if (!selectedAsset || !selectedEntryId) {
            return undefined;
        }
        const selected = getSelectedHistoryEntry(selectedEntryId);
        if (isUndefined(selected)) {
            return undefined;
        }

        switch (eventTypeFromUrl) {
            case HistoryEventType.VEHICLE: {
                const vehicleHistoryEntry = selected as HistoryVehicleEntry;
                return <VehicleDetailsPane entry={vehicleHistoryEntry} handleClose={closeRightPane} />;
            }
            case HistoryEventType.TRAILER: {
                const trailerHistoryEntry = selected as HistoryTrailerEntry;
                return <TrailerDetailsPane entry={trailerHistoryEntry} handleClose={closeRightPane} />;
            }
            case HistoryEventType.DRIVER: {
                const driverHistoryEntry = selected as HistoryDriverEntry;
                return <DriverDetailsPane entry={driverHistoryEntry} handleClose={closeRightPane} />;
            }
            default:
                return undefined;
        }
    })();

    const leftPane = React.useMemo(() => {
        if (!leftPaneIsOpen) {
            return undefined;
        }

        switch (eventTypeFromUrl) {
            case HistoryEventType.VEHICLE: {
                const selectVehicle = (selectedAssets: AssetReference[]) => {
                    const selected = selectedAssets.length ? selectedAssets[0] : undefined;
                    history.push(
                        constructHistoryUrl({
                            eventType: HistoryEventType.VEHICLE,
                            selectedAssetId: selected?.id,
                            selectedAssetType: selected?.type,
                        })
                    );
                };
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Vehicle]}
                        onSelectedAssetIdsChange={selectVehicle}
                        preferencesKey={HISTORY_VEHICLE_ASSETSELECTOR_USERPREFERENCES_KEY}
                        selectedAssetIds={selectedAssetIds}
                        singleSelectionMode
                    />
                );
            }

            case HistoryEventType.TRAILER: {
                const selectTrailer = (selectedAssets: AssetReference[]) => {
                    const selected = selectedAssets.length ? selectedAssets[0] : undefined;
                    history.push(
                        constructHistoryUrl({
                            eventType: HistoryEventType.TRAILER,
                            selectedAssetId: selected?.id,
                            selectedAssetType: selected?.type,
                        })
                    );
                };
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Trailer, AssetType.Vehicle]}
                        onSelectedAssetIdsChange={selectTrailer}
                        preferencesKey={HISTORY_TRAILER_ASSETSELECTOR_USERPREFERENCES_KEY}
                        selectedAssetIds={selectedAssetIds}
                        singleSelectionMode
                    />
                );
            }

            case HistoryEventType.DRIVER: {
                const selectDriver = (selectedAssets: AssetReference[]) => {
                    const selected = selectedAssets.length ? selectedAssets[0] : undefined;
                    history.push(
                        constructHistoryUrl({
                            eventType: HistoryEventType.DRIVER,
                            selectedAssetId: selected?.id,
                            selectedAssetType: selected?.type,
                        })
                    );
                };
                return (
                    <AssetSelector
                        assetTypes={[AssetType.Driver, AssetType.Vehicle]}
                        onSelectedAssetIdsChange={selectDriver}
                        preferencesKey={HISTORY_DRIVER_ASSETSELECTOR_USERPREFERENCES_KEY}
                        selectedAssetIds={selectedAssetIds}
                        singleSelectionMode
                    />
                );
            }

            default:
                return undefined;
        }
    }, [eventTypeFromUrl, history, leftPaneIsOpen, selectedAssetIds]);

    const getViewContent = () => {
        if (isUndefined(selectedAssetId)) {
            return (
                <div className={classes.messageText} data-id="no-selection">
                    {getSelectAssetText()}
                </div>
            );
        }

        if (contentRejected) {
            return (
                <div className={classes.messageText} data-id="loading-failed">
                    {t('failed-to-retrieve-data')}
                </div>
            );
        }

        const summary =
            eventTypeFromUrl === HistoryEventType.DRIVER &&
            selectedAssetType &&
            historyUserPreferences.driverActivityGraphToggleState ? (
                <DriverEventsGraphPanel
                    dataSource={dataSource as HistoryDriverEntry[]}
                    selectedAssetType={selectedAssetType}
                    selectHighlightedRow={setHoveredEntryId}
                    selectRow={selectRowFromGraph}
                />
            ) : undefined;

        return (
            <Box display="flex" flexDirection="column" width="100%">
                <SplitView>
                    {{
                        bottom: (
                            <HistoryList
                                dataSource={dataSource}
                                gridRef={vtRef}
                                highlightedEntryId={currentClicked ?? hoveredEntryId}
                                historyEventType={eventTypeFromUrl}
                                onRowContextMenu={handleRowClick}
                                selectedEntryId={selectedEntryId}
                                selectRow={setSelectedEntryId}
                            />
                        ),
                        top: summary,
                    }}
                </SplitView>
            </Box>
        );
    };

    if (!permittedEventTypes.includes(eventTypeFromUrl)) {
        return <Redirect to={SceneRoutes.UNAUTHORIZED} />;
    }

    if (isInitialLoad) {
        if (error) {
            return <AskForPageReload />;
        }

        if (isFetching) {
            return <SceneLoader />;
        }
    }

    if (
        isUndefined(selectedAssetIdFromUrl) &&
        historyRouteState.selectedAssetType &&
        historyRouteState.selectedAssetId
    ) {
        // if there is no params from URL but there is in etherealState, take them and do redirection
        return (
            <Redirect
                to={constructHistoryUrl({
                    eventType: eventTypeFromUrl,
                    selectedAssetId: historyRouteState.selectedAssetId,
                    selectedAssetType: historyRouteState.selectedAssetType,
                })}
            />
        );
    }

    return (
        <div className={classes.root} data-id="history">
            <PageTemplate
                header={getHeaderBar()}
                leftPaneElement={leftPane}
                leftPaneToggleTitle={leftPaneIsOpen ? t('collapse-asset-selector') : t('expand-asset-selector')}
                rightPaneElement={rightPane}
                toggleLeftPaneVisibility={toggleLeftPaneVisibility}
            >
                {getViewContent()}
                <Backdrop className={classes.backDrop} data-id="back-drop" open={contentLoading}>
                    <CircularProgress className={classes.loader} data-id="backdrop-loader" size={120} />
                </Backdrop>
            </PageTemplate>
            {isOpen && !isUndefined(currentClicked) && (
                <HistoryEventContextMenu
                    eventId={currentClicked}
                    eventType={eventTypeFromUrl}
                    onClose={handleClose}
                    position={position}
                />
            )}
        </div>
    );
};
